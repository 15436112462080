import axios from 'axios';
import { Image as VanImage, Tag, Button, Icon, SubmitBar, showDialog, ActionSheet, Popup, Overlay, Cell, CellGroup } from 'vant';
export default {
  data() {
    return {
      skuNeedPay: '',
      skuTotal: '',
      skuRefundTotal: '',
      skuType: '',
      storeName: '',
      storeOpenTime: '',
      storeCloseTime: '',
      storeAddress: '',
      skuCantUse: '',
      skuid: '',
      actionshow: false,
      skuStoreUrl: '',
      skuH5Url: '',
      showtips: false,
      cardNum: 0,
      storeImg: '',
      skuWriteType: '',
      isCKA: 0
    };
  },
  methods: {
    onSubmit() {
      window.location.href = 'https://sct.qaqwq.com/gotowechat';
    }
  },
  mounted() {
    var dis = this.$route.params.dis;
    var skuid = this.$route.params.skuid;
    axios.post('https://sct.qaqwq.com/api/xcx/getskuinfo', {
      storeId: skuid
    }, {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.data.length > 0) {
        var info = res.data[0];
        this.skuNeedPay = info.skuNeedPay, this.skuTotal = info.skuTotal, this.skuRefundTotal = info.skuRefundTotal, this.skuType = info.skuType, this.storeName = info.storeName, this.storeOpenTime = info.storeOpenTime, this.storeCloseTime = info.storeCloseTime, this.storeAddress = info.storeAddress, this.skuCantUse = info.skuCantUse, this.skuid = info.skuid, this.skuStoreUrl = info.skuStoreUrl, this.skuH5Url = info.skuH5Url, this.storeImg = info.storeImg, this.skuWriteType = info.skuWriteType, this.isCKA = info.isCKA;
      }
    });
    axios.get('https://sct.qaqwq.com/api/xcx/getmyinfo', {
      headers: {
        'Content-Type': 'application/json',
        'token': localStorage.getItem('token')
      }
    }).then(res => {
      if (res.data.code == 201) {
        this.cardNum = res.data.other.canuseCoupon;
      }
    });
  }
};